export const descriptionWord = {
  uk: "Опис",
  en: "Description",
};

export const certifiedTeachers = {
  uk: "Дипломовані викладачі",
  en: "Certified teachers",
};

export const nativeSpeakers = {
  uk: "Носії мови з США та Англії",
  en: "Native speakers from the USA and the UK",
};

export const speakingClubs = {
  uk: "Розмовні клуби кожного тижня",
  en: "Weekly speaking clubs",
};

export const courseDuration = {
  uk: "Тривалість курсу",
  en: "Course duration",
};

export const lessonDuration = {
  uk: "Тривалість уроку",
  en: "Lesson duration",
};

export const groupSize = {
  uk: "Групи від 4-х до 8-ми осіб",
  en: "Groups of 4 to 8 people",
};

export const payment = {
  uk: "Оплата",
  en: "Payment",
};

export const monthlyPayment = {
  uk: "помісячна, здійснюється перед початком навчання",
  en: "monthly, prior to the start of study",
};

export const paymentPerLesson = {
  uk: "Вартість за урок",
  en: "Cost per lesson",
};

export const studySchedule = {
  uk: "Розклад занять",
  en: "Study schedule",
};
